@charset "UTF-8";
@import url(https://fonts.googleapis.com/css?family=Poppins:100,200,400,300,500,600,700);

/*

CONTENTS:
1. Global
2. Login
3. Snackbars (for Updates)
4. Dialogs
5. Drawers
6. User Registration
7. Provider Registration
8. Requests
9. Groups
10. MAAIF Integration
11. Other

*/

/* Global
   ========================================================================== */

html {
  font-size: 16px;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.hidden {
  display: none;
}

.MuiPaper-root [class*='MuiAppBar'] .MuiTab-textColorPrimary.Mui-selected {
  color: #3f51b5 !important;
}

.MuiPaper-root [class*='MuiAppBar'] .MuiTabs-indicator {
  background-color: #3f51b5 !important;
}

.MuiPaper-root .MuiTabs-indicator {
  background-color: #3490dc !important;
}

[class*='MuiRadio'][class*='Mui-checked'] {
  color: #3f51b5 !important;
}

[class*='MuiCheckbox'][class*='Mui-checked'] {
  color: #3f51b5 !important;
}

.list-page > .MuiToolbar-root .MuiButton-textPrimary:hover,
.list-page > .MuiToolbar-root .MuiButton-textPrimary:focus,
.list-page > .MuiToolbar-root .MuiButton-textPrimary:active {
  color: #DDDDDD !important;
}

.list-page > .MuiToolbar-root .MuiButton-root {
  font-size: 14px;
  color: #FFFFFF;
  text-align: center;
  border-radius: 4px;
  background: linear-gradient(#069751, #066a3a);
  padding: 0.7rem 3.265rem;
  margin-top: 0.5rem;
}

.list-page > .MuiToolbar-root .MuiButton-root .MuiSvgIcon-root {
  display: none;
}

.MuiPaper-root .MuiTable-root > a, 
.MuiPaper-root .MuiTable-root > a.MuiButton-root,
.MuiPaper-root .MuiTable-root > a > .MuiTypography-root {
  color: #3490dc !important;
}

.MuiPaper-root .MuiTable-root > a:hover,
.MuiPaper-root .MuiTable-root > a:focus,
.MuiPaper-root .MuiTable-root > a:active {
  color: #1d68a7 !important;
  text-decoration: underline;
}

.MuiPaper-root .MuiTable-root > a.MuiButton-root:hover, 
.MuiPaper-root .MuiTable-root > a.MuiButton-root:focus {
  color: #1d68a7 !important;
  background-color: rgba(63, 81, 181, 0.08) !important;
}

.MuiPaper-root .MuiTable-root .MuiButton-textPrimary,
.MuiPaper-root .MuiTable-root .MuiIconButton-root {
  color: #3490dc !important;
}

.MuiPaper-root .MuiTable-root .MuiButton-textPrimary:hover,
.MuiPaper-root .MuiTable-root .MuiButton-textPrimary:focus {
  color: #1d68a7 !important;
  background-color: rgba(63, 81, 181, 0.08) !important;
}

.MuiPaper-root .MuiCardContent-root > .ra-input {
  display: block !important;
}

/** Other **/

.MuiCardHeader-content {
  flex-grow: 0 !important;
}

.add-to-program > .MuiFormLabel-root {
  color: #3f51b5 !important;
}

.add-to-program > .MuiFilledInput-underline:after {
  border-color: #3f51b5 !important;
}

.AkAction--popup .MuiMenu-list>.MuiMenuItem-root:not(:first-child) {
  border-top: 1px solid #F2F2F2;
}

/* We hide the checkboxes within the list (mobile) */

.MuiPaper-root .MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>th:first-child, .MuiPaper-root .MuiTable-root>.MuiTableBody-root>.MuiTableRow-root>td:first-child {
  display: none;
}

.MuiPaper-root .MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>th:last-child {
  text-align: center;
  padding-right: 30px;
}

@media (max-width: 599.98px) {
  /* list page (tested on user-list) */
  .layout header>.MuiToolbar-root {
    padding-right: 18px;
  }
  .list-page .MuiPaper-root:first-of-type {
    margin-top: -45px;
  }
  .list-page>.MuiToolbar-root button.MuiButtonBase-root {
    display: none;
  }
  .list-page [class*=MuiTabs] [class*=MuiButtonBase]>.MuiTab-wrapper {
    align-items: flex-start;
  }
}


/* Login
   ========================================================================== */


.akb .MuiPaper-root[class*='MuiCard']>div:first-of-type [class*='MuiAvatar'] {
  background-color: #3f51b5 !important;
}


/* Snackbar (for Updates)
   ========================================================================== */


.UpdatesBar [class*='MuiSnackbarContent'] [class*='message'] .MuiButton-textPrimary:hover, .UpdatesBar [class*='MuiSnackbarContent'] [class*='message'] .MuiButton-textPrimary:focus, .UpdatesBar [class*='MuiSnackbarContent'] [class*='message'] .MuiButton-textPrimary:active {
  color: #fff !important;
}

.UpdatesBar [class*='MuiSnackbarContent'] [class*='action'] {
  margin-right: 0px !important;
  padding-left: 6px !important;
}

.UpdatesBar [class*='MuiSnackbarContent'] [class*='action'] [class*='MuiIconButton'] {
  color: #ccc;
}

.UpdatesBar [class*='MuiSnackbarContent'] [class*='action'] [class*='MuiIconButton']:hover, .UpdatesBar [class*='MuiSnackbarContent'] [class*='action'] [class*='MuiIconButton']:focus, .UpdatesBar [class*='MuiSnackbarContent'] [class*='action'] [class*='MuiIconButton']:active {
  color: #fff !important;
}


/* Dialogs
   ========================================================================== */

.MuiDialog-paperWidthSm {
  max-width: 850px !important;
}


/* Drawers
   ========================================================================== */

@media (max-width: 599.98px) {

  .MuiDrawer-paperAnchorLeft {
    min-height: 100%;
  }
  [class*=MuiDrawer] [class*=MuiMenuItem] {
    font-size: 1.125rem;
    padding-top: 11.5px;
    padding-bottom: 11.5px;
  }
}


/* User Registration
   ========================================================================== */


.user-registration .MuiFormControl-root[class*='edit']>div:first-of-type>div:first-child, .register-form .MuiFormControl-root[class*='create']>div:first-of-type>div:first-child {
  background-color: rgba(0, 0, 0, 0.09) !important;
}

.user-registration .add-to-group .MuiFormControl-root:not(.branch) .MuiInputBase-root {
  padding-top: 16px;
  padding-bottom: 1px;
  padding-left: 12px;
  position: relative;
  transition: background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  background-color: rgba(0, 0, 0, 0.09);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.user-registration .MuiFormLabel-root {
  color: #3f51b5 !important;
}

.user-registration .add-to-group .MuiInput-underline:before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

.user-registration .add-to-group .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

.user-registration .add-to-group .MuiInput-underline:after {
  border-color: #3f51b5 !important;
}

.user-registration .add-to-group .MuiFormControl-root label.MuiInputLabel-formControl {
  margin-left: 12px !important;
  margin-top: -6px;
}

.user-registration .add-to-group .MuiFormControl-root label+.MuiInput-formControl {
  margin-top: -1px !important;
}

.user-registration .add-to-group .MuiFormControl-root label.MuiInputLabel-formControl.MuiInputLabel-shrink {
  margin-left: 12px !important;
  margin-top: -1px;
  transform: translate(0, 4.5px) scale(0.75) !important;
}

.user-registration .add-to-group .select-group {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
  margin-top: 60px;
  width: 280px;
  max-width: 300px;
  max-height: 50vh !important;
  overflow-y: auto;
}

@media (min-width: 960px) { 
  .basic-form > .MuiCardContent-root:first-child, 
  .register-form > .MuiCardContent-root:first-child {
    padding-top: 26px !important;
  }
  .basic-form .MuiFormControl-root[class*='input'], .basic-form .MuiFormControl-root, .register-form .MuiFormControl-root[class*='input'], .register-form .MuiFormControl-root {
    margin: 0.5em 1.125em 1.125em 0 !important;
  }
  .basic-form .MuiFormControl-root[class*='input']>div, .basic-form .MuiFormControl-root>div, .register-form .MuiFormControl-root[class*='input']>div, .register-form .MuiFormControl-root>div {
    width: 206px !important;
  } 

  .register-form fieldset.MuiFormControl-root > div {
    width: 320px !important;
  } 

  .basic-form [class*='MuiFormGroup'],
  .register-form [class*='MuiFormGroup'] {
    display: inline-flex !important;
    flex-direction: row !important;
    margin: 0.625em 1em 1.25em 0 !important;
  }
}

@media (max-width: 959.99px) {
  .basic-form .MuiFormControl-root[class*='input'], .basic-form .MuiFormControl-root, .register-form .MuiFormControl-root[class*='input'], .register-form .MuiFormControl-root {
    margin: 0.5em 1.375em 0.9375em 0 !important;
  }
  .basic-form .MuiFormControl-root[class*='input']>div, .basic-form .MuiFormControl-root>div, .register-form .MuiFormControl-root[class*='input']>div, .register-form .MuiFormControl-root>div {
    width: 250px !important;
  }
}

.simple-form .MuiToolbar-root {
  display: flex !important;
  justify-content: space-between !important;
  margin-top: 8px;
}

.simple-form.register-form > .MuiToolbar-root > .save-and-exit-button {
  visibility: hidden;
}

.simple-form .AkRegisterForm-body {
  width: auto;
}

.AkRegisterForm-body .capitalize .MuiInputBase-root > input {
  text-transform: capitalize !important;
}

.simple-form .AkRegisterForm-role {
  display: inline-flex;
  margin-right: 10px;
}

@media (min-width: 960px) {
  .simple-form {
    min-height: 540px;
  }
  
  .simple-form .MuiToolbar-root {
    margin-top: 44px;
  }
}

.MuiButton-root:focus, .MuiButton-root.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
}

.MuiPaper-root .simple-form > .MuiToolbar-root .MuiButton-containedPrimary:first-child,
.MuiPaper-root .simple-form > .MuiToolbar-root .MuiButton-containedPrimary:hover,
.MuiPaper-root .simple-form > .MuiToolbar-root .MuiButton-containedPrimary:focus,
.MuiPaper-root .simple-form > .MuiToolbar-root .MuiButton-containedPrimary:active {
  background: linear-gradient(#069751, #066a3a);
}

.MuiPaper-root .basic-form > .MuiToolbar-root .MuiButton-containedPrimary,
.MuiPaper-root .basic-form > .MuiToolbar-root .MuiButton-containedPrimary:hover,
.MuiPaper-root .basic-form > .MuiToolbar-root .MuiButton-containedPrimary:focus,
.MuiPaper-root .basic-form > .MuiToolbar-root .MuiButton-containedPrimary:active {
  background: linear-gradient(#069751, #066a3a);
}

.MuiPaper-root .register-form > .MuiToolbar-root .MuiButton-containedPrimary,
.MuiPaper-root .register-form > .MuiToolbar-root .MuiButton-containedPrimary:hover,
.MuiPaper-root .register-form > .MuiToolbar-root .MuiButton-containedPrimary:focus,
.MuiPaper-root .register-form > .MuiToolbar-root .MuiButton-containedPrimary:active {
  background: linear-gradient(#069751, #066a3a);
}

.MuiPaper-root .individual-pro-form > .MuiToolbar-root .MuiButton-containedPrimary,
.MuiPaper-root .individual-pro-form > .MuiToolbar-root .MuiButton-containedPrimary:hover,
.MuiPaper-root .individual-pro-form > .MuiToolbar-root .MuiButton-containedPrimary:focus,
.MuiPaper-root .individual-pro-form > .MuiToolbar-root .MuiButton-containedPrimary:active {
  background: linear-gradient(#069751, #066a3a);
}

.MuiPaper-root .company-pro-form > .MuiToolbar-root .MuiButton-containedPrimary,
.MuiPaper-root .company-pro-form > .MuiToolbar-root .MuiButton-containedPrimary:hover,
.MuiPaper-root .company-pro-form > .MuiToolbar-root .MuiButton-containedPrimary:focus,
.MuiPaper-root .company-pro-form > .MuiToolbar-root .MuiButton-containedPrimary:active {
  background: linear-gradient(#069751, #066a3a);
}

.MuiPaper-root .MuiToolbar-root [class='MuiCircularProgress'] {
  color: #eff0f1 !important;
}

/* hide or show edit-tabs (./users/form/AbFormTab.js) */

.form-tab.not-farmer, .form-tab.not-provider {
  display: none !important;
}

@media (max-width: 599.98px) {

  /* We hide the third item (branches) from within service providers list */
  .MuiPaper-root .MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>th:nth-child(5), .MuiPaper-root .MuiTable-root>.MuiTableBody-root>.MuiTableRow-root>td:nth-child(5) {
    display: none;
  }
  .MuiPaper-root .MuiTable-root>.MuiTableBody-root>.MuiTableRow-root>td:last-child .MuiSvgIcon-root {
    font-size: 1.85rem;
  }
  .MuiPaper-root .MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>th:last-child {
    padding-right: 12px;
  }
  .MuiPaper-root .MuiTable-root .MuiTableCell-root {
    padding-left: 2px;
    padding-right: 3px;
  }
 .MuiPaper-root .MuiTable-root .MuiTableBody-root > .MuiTableRow-root > .MuiTableCell-root:last-child {
    text-align: center;
  }

  /* registration form */
  main {
    width: 100%;
  }
  main [class*='page'] .MuiPaper-root:first-of-type {
    margin-left: 10px;
    margin-right: 10px;
  }

  main [class*='page'] .MuiPaper-root.MuiExpansionPanel-root:first-of-type {
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
  }

  main [class*='page'] .MuiPaper-root.MuiExpansionPanel-root:first-of-type {
    padding: 0px;
  }

  /* avoid overriding list style (alignment) on mobile */
  main .list-page .MuiPaper-root:first-of-type {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex: 1 2 100%;
    margin-left: 4px;
    margin-right: 4px;
  }
  [class*=MuiTypography].AkRegisterForm-title {
    margin-top: 0.35em;
    margin-bottom: 0px;
  }
  .simple-form .AkRegisterForm-body {
    width: 100% !important;
    text-align: center;
  }
  .simple-form .AkRegisterForm-body .MuiFormGroup-root {
    justify-content: center;
  }
  .simple-form .check {
    width: 102px !important;
  }
  .simple-form .create {
    margin-left: -3.5px;
  }
  .basic-form .MuiFormControl-root[class*='input'], .basic-form .MuiFormControl-root, .register-form .MuiFormControl-root[class*='input'], .register-form .MuiFormControl-root {
    margin-right: 0 !important;
    text-align: left;
  }
  .basic-form .MuiFormControl-root[class*='input']>div, .basic-form .MuiFormControl-root>div, .register-form .MuiFormControl-root[class*='input']>div, .register-form .MuiFormControl-root>div, .simple-form.provider .address.details.edit ul section>div:not(:nth-child(4)) {
    width: 292px !important;
  }
  .simple-form.provider .address.details.edit ul section>div:not(:nth-child(4)) {
    display: inline-block;
  }
  .simple-form .MuiToolbar-root .MuiButtonBase-root {
    font-size: 0.85em;
  }
  .simple-form > .MuiToolbar-root .MuiSvgIcon-root {
    display: none;
  }
  .simple-form.provider .MuiToolbar-root {
    padding: 6px 28.75px;
  }
  .simple-form.provider .personel {
    margin-left: 0px;
  }

  .simple-form.provider .centered {
    text-align: center;
  }

  .simple-form.provider .MuiToolbar-root .direction .MuiSvgIcon-root {
    display: inline-block;
    font-size: 2rem;
  }
  .simple-form .AkRegisterForm-role {
    margin-left: -15px;
  }
  .simple-form hr {
    color: rgba(0, 0, 0, 0.12) !important;
  }
  .simple-form .MuiFormLabel-root {
    font-size: 1.25rem;
  }
}

/* Provider Registration
   ========================================================================== */


.individual-pro-form > .MuiCardContent-root:first-child {
  padding-top: 0 !important;
}

.individual-pro-form .MuiCardContent-root .ra-input:nth-child(2),
.individual-pro-form .MuiCardContent-root .ra-input:nth-child(4) {
  margin-top: 12.5px !important;
}

.individual-pro-form .MuiCardContent-root .ra-input > [class*='MuiBox'] {
  width: 100% !important;
}

.individual-pro-form .MuiFormControl-root[class*='edit']>div:first-of-type>div:first-child {
  padding: 16.5px 8px !important;
  background-color: rgba(0, 0, 0, 0.09) !important;
}

.individual-pro-form .MuiFormControl-root[class*='edit'][class*='registered']>div:first-of-type>div:first-child, .individual-pro-form .MuiFormControl-root[class*='edit'][class*='registered']>div:first-of-type>div:first-child {
  margin-top: 21px !important;
}

.individual-pro-form legend.MuiFormLabel-root {
  font-size: 1.375em !important;
  color: rgba(0, 0, 0, 0.65) !important;
}

@media (min-width: 960px) {
  .individual-pro-form .MuiFormControl-root[class*='input'], .individual-pro-form .MuiFormControl-root {
    margin: 0.5em 1.875em 1.125em 0 !important;
  }
  .individual-pro-form .MuiFormControl-root[class*='input']>div, .individual-pro-form .MuiFormControl-root>div {
    width: 250px !important;
  }
}

.individual-pro-form fieldset.MuiFormControl-root {
  margin-bottom: 20px !important;
}

.company-pro-form > .MuiCardContent-root:first-child {
  padding-top: 0 !important;
}

.company-pro-form .MuiCardContent-root .ra-input:not(:first-child) {
  margin-top: 12.5px !important;
}

.company-pro-form .MuiCardContent-root .ra-input:last-child {
  margin-top: 30px !important;
}

.company-pro-form .MuiCardContent-root .ra-input > [class*='MuiBox'] {
  width: 100% !important;
}

.company-pro-form .MuiFormControl-root[class*='edit']>div:first-of-type>div:first-child {
  padding: 16.5px 8px !important;
  background-color: rgba(0, 0, 0, 0.09) !important;
}

.company-pro-form .MuiFormControl-root[class*='edit'][class*='registered']>div:first-of-type>div:first-child, .company-pro-form .MuiFormControl-root[class*='edit'][class*='credentials']>div:first-of-type>div:first-child {
  margin-top: 58px !important;
}

.company-pro-form [class*='MuiExpansionPanel'] .MuiFormControl-root[class*='edit']>div:first-of-type>div:first-child {
  margin-top: -12px !important;
}

.company-pro-form .MuiCardContent-root .ra-input:nth-child(5) [class*='MuiExpansionPanelDetails'] {
  flex-flow: row wrap !important;
}

.company-pro-form [class*='MuiExpansionPanel'] .MuiFormControl-root[class*='edit']>div:first-of-type>div:first-child {
  margin-top: -12px !important;
}

.company-pro-form .MuiFormControl-root[class*='edit'][class*='identity']>div:first-of-type>div:first-child, .company-pro-form .MuiFormControl-root[class*='edit'][class*='identity']>div:first-of-type>div:first-child {
  margin-top: 18px !important;
}

.company-pro-form legend.MuiFormLabel-root {
  font-size: 1.375em !important;
  color: rgba(0, 0, 0, 0.65) !important;
}

@media (min-width: 960px) {
  .company-pro-form .MuiFormControl-root[class*='input'], .company-pro-form .MuiFormControl-root[class*='MuiTextField'] {
    margin: 0.5em 1.875em 1.125em 0 !important;
  }
  .company-pro-form .MuiFormControl-root[class*='input']>div, .company-pro-form .MuiFormControl-root[class*='MuiTextField']>div {
    width: 250px !important;
  }
}

.company-pro-form fieldset.MuiFormControl-root {
  margin-bottom: 20px !important;
}

.simple-form.provider [class*='dropZone'] {
  min-height: 32px !important;
}

.simple-form.provider .upload [class*='dropZone'] {
  padding: 40px 8px;
  margin-bottom: 45px;
}

.simple-form [class*='MuiStepper'] {
  padding-top: 10px !important;
  padding-bottom: 48px !important;
}

.simple-form [class*='MuiStepper'] [class*='MuiStepLabel'].MuiTypography-root {
  font-size: 22px !important;
}

.simple-form.provider > .MuiCardContent-root:first-child {
  padding-bottom: 26px !important;
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.simple-form .certification section>.ra-input:not(:last-child), .simple-form .address.details.edit section>.ra-input:not(:nth-child(3)) {
  display: inline-block;
}

.simple-form .address.details.edit section>.ra-input:nth-child(4), .simple-form .address.details.edit section>.ra-input:nth-child(4)>div, .simple-form .address.details.edit section>.ra-input:nth-child(5) {
  width: 100%;
}

.simple-form .address.details.edit section .MuiFormControl-root {
  vertical-align: baseline;
  margin-bottom: 32px !important;
}

@media (min-width: 960px) {
  .basic-form.provider .MuiFormControl-root[class*='input'], .basic-form.provider .MuiFormControl-root, .register-form.provider .MuiFormControl-root[class*='input'], .register-form.provider .MuiFormControl-root {
    margin: 0.5em 1.125em 1.125em 0 !important;
  }
  .simple-form.provider .address.details>[class*='MuiBox']>div:last-of-type .MuiFormControl-root {
    margin-right: 4.375em !important;
  }
}

.simple-form.provider .certification .MuiFormControl-root[class*='input']>div {
  width: 100% !important;
}

.simple-form.provider .certification>.MuiFormControl-root>ul>li {
  margin-right: 20px;
}

.simple-form.provider .address .MuiFormControl-root:not(.branch) .MuiInputBase-root {
  padding-top: 16px;
  padding-bottom: 1px;
  padding-left: 12px;
  position: relative;
  transition: background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  background-color: rgba(0, 0, 0, 0.09);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.simple-form.provider .address .MuiFormControl-root:not(.branch) .MuiFilledInput-root {
  padding-top: 0;
}

/* Service provider form */

.simple-form.provider .address .select-address {
  display: inline-block !important;
}

.simple-form.provider .address label+.MuiInput-formControl {
  margin-top: -1px !important;
}

.simple-form.provider .address .MuiFormControl-root:not(.branch) label.MuiInputLabel-formControl {
  margin-left: 12px !important;
  margin-top: -6px;
}

.simple-form.provider .address .MuiFormControl-root:not(.branch) label.MuiInputLabel-filled {
  margin-left: 0px !important;
  margin-top: 0px;  
}

.simple-form.provider .address .MuiFormControl-root label.MuiInputLabel-formControl.MuiInputLabel-shrink {
  margin-left: 12px !important;
  margin-top: -1px;
}

.simple-form.provider .address .MuiInputLabel-shrink {
  transform: translate(0, 4.5px) scale(0.75) !important;
}

.simple-form.provider .address label+.MuiInputBase-input {
  padding: 23px 12px 10px !important;
}

.simple-form.provider .address .MuiFilledInput-root:hover {
  background-color: rgba(0, 0, 0, 0.13);
}

.simple-form.provider .address .MuiInput-underline:before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

.simple-form.provider .address .select-address .MuiInput-underline:after {
  border-bottom: 2px solid #3f51b5 !important;
}

.simple-form.provider.first>.MuiToolbar-root>button:first-of-type {
  visibility: hidden;
}

.simple-form .unique-field, .simple-form .hidden {
  display: none !important;
}

.simple-form.provider > .MuiToolbar-root > .MuiButton-root,
.simple-form.provider > .MuiToolbar-root .btn-group > .MuiButton-root {
  padding: 8px 11.5px !important;
  font-size: 0.875em !important;
}

.simple-form.provider>.MuiToolbar-root .btn-group>.MuiButton-root:last-child {
  margin-left: 15px;
}

.simple-form.provider .download .MuiButton-root {
  padding: 3.5px 9.5px !important;
  font-size: 1em !important;
}

.simple-form.provider .download .MuiButton-root:hover, .simple-form.provider .download .MuiButton-root:focus, .simple-form.provider .download .MuiButton-root:active {
  color: #3f51b5 !important;
  background-color: rgba(63, 81, 181, 0.08) !important;
}

.simple-form.provider .download .MuiButton-root {
  margin-left: 3.85em;
}

.simple-form.provider .address:first-of-type .MuiFormControl-root {
  margin-bottom: 12px !important;
}

.simple-form.provider .address:nth-of-type(3n) .MuiFormControl-root:first-of-type {
  display: none !important;
}

.simple-form.provider .address .MuiFormLabel-root, .simple-form.provider .address .MuiFormLabel-root.Mui-focused, .simple-form.basic-form .MuiFormLabel-root.Mui-focused {
  color: #3f51b5 !important;
}

.simple-form.provider .address:nth-of-type(3n) .MuiFormLabel-root, .simple-form.provider .address:nth-of-type(3n) .MuiFormLabel-root.Mui-focused {
  color: rgba(0, 0, 0, 0.7) !important;
}

.simple-form.provider .address .MuiInput-root.Mui-focused:after, .simple-form.basic-form .MuiInput-root.Mui-focused:after {
  border-color: #3f51b5 !important;
}

.simple-form.provider .address .select-location {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
  margin-top: 60px;
  width: 280px;
  max-width: 300px;
  max-height: 50vh !important;
  overflow-y: auto;
}

@media (max-width: 599.98px) {

  /* Provider registration form */
  .simple-form.provider .address, .simple-form.provider .select-services {
    text-align: center;
  }
  .simple-form.provider .address .select-location {
    max-width: 250px;
    margin-right: auto;
    margin-left: auto;
  }
  .simple-form.provider .address .select-location .MuiListItem-root {
    padding-left: 6px;
    padding-right: 6px;
  }

  .simple-form.provider > .MuiCardContent-root:first-child {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .simple-form.provider .stepper-lg-header {
    display: none;
  }
  .simple-form.provider .stepper-sm-header {
    display: block !important;
    font-size: 1.5em;
    padding-top: 9px;
    padding-bottom: 20px;
  }
  .simple-form .MuiStepper-horizontal {
    flex-direction: column;
  }
  .simple-form .MuiStepper-horizontal .MuiStep-horizontal:not(:last-child) {
    padding-bottom: 8px;
  }
  main [class*='page'] .MuiPaper-root[class*='MuiStepper']:first-of-type {
    margin-left: 0px;
    margin-right: 0px;
  }
  .simple-form [class*='MuiStepper'] [class*='MuiStepLabel'].MuiTypography-root {
    font-size: 19.25px !important;
  }
  .stretch {
    margin-bottom: 250px !important;
  }
}

/* Handling the provider (stepper) form */

.simple-form.provider .steps {
  margin: 1em 0 0 1em;
}

.simple-form.provider .personel {
  margin-left: 48px;
}

.simple-form.provider .address.details ul section>div:not(:nth-child(3)) {
  width: 269px;
}

@media (min-width: 960.98px) {
  .simple-form.provider .address.details ul section>div:not(:nth-child(3)) {
    width: 224px;
  }
}

@media (max-width: 718.98px) {
  .simple-form.provider .address.details ul section>div:not(:nth-child(3)) {
    width: 292px;
  }
  .simple-form.provider .address.details ul section>div:first-child > .MuiFormControl-root,
  .simple-form.provider .address.details ul section>div:nth-child(2) > .MuiFormControl-root {
    margin-bottom: 12px !important;
  }
}

@media (max-width: 599.98px) {
  .simple-form.provider .address.details ul section>div:first-child > .MuiFormControl-root,
  .simple-form.provider .address.details ul section>div:nth-child(2) > .MuiFormControl-root {
    margin-right: 36px !important;
  }
}

@media (max-width: 425.98px) {
  .simple-form.provider .address.details ul section>div:first-child > .MuiFormControl-root,
  .simple-form.provider .address.details ul section>div:nth-child(2) > .MuiFormControl-root {
    margin-left: 2.25px !important;
  }
}

/* hide "remove" button for the default (first) branch location */

.simple-form.provider .address.details ul>li:first-child > span {
  display: none;
}

/* hide the "add" button from provider edit-mode */

.simple-form.provider .address.details ul>li:not(:first-child) {
  display: none;
}

/* hide the small header, if not mobile */

.simple-form.provider .stepper-sm-header {
  display: none;
}

.simple-form.provider .address.details ul>li {
  border-bottom: none;
}

.simple-form.provider .MuiFormControl-root.address.details {
  margin-top: 0px;
  margin-bottom: 0px;
}

/* handle provider services registration */

.simple-form.services .MuiFilledInput-underline:after {
  border-bottom: 2px solid #3f51b5 !important;
}

.simple-form.services .MuiFormLabel-root.Mui-focused {
  color: #3f51b5 !important;
}

.programs-dialog .MuiPaper-root {
  min-width: 350px;
}

/* support view increase; when searching village */

.stretch {
  margin-bottom: 180px !important;

}/* support view increase; when searching existing user */

.stretch--district,
.stretch--user {
  margin-bottom: 40px !important;
}

.form--subsection {
  border-bottom: 1px solid rgba(0,0,0,0.045);
  margin-bottom: 30px !important;
}

/* support stretching dashboard to fill mobile view-port*/

.agent--view {
  flex-direction: row !important;
}

/* provider services list/card */

.simple-form.provider .select-services .MuiButton-root {
  margin: 0 37.25px;
}

.simple-form.provider .select-services .MuiButton-root:first-of-type {
  margin: 15px 9.25px 42.5px;
}

.simple-form.add-service .MuiFormControl-root .MuiFilledInput-underline:after,
.simple-form.provider .select-services .MuiFormControl-root .MuiFilledInput-underline:after {
  border-bottom-color: #3f51b5 !important;
}

.simple-form.provider .services-list {
  padding-right: 20px;
}

.simple-form.provider .services-list .services-card {
  width: 100%;
  min-height: 70px;
  padding: 0px;
  margin: 0.85em 0.5em 0.85em 0;
  display: flex;
}

.simple-form.provider .services-title {
  align-items: flex-start;
  background: #fafafa;
  width: 260px;
  padding: 4px 16px;
}

.simple-form.provider .services-title>.MuiCardHeader-root span.MuiTypography-body2 {
  font-size: 1rem;
}

.simple-form.provider .services-title>.MuiCardHeader-root>span.MuiTypography-root .services-measure {
  font-size: 0.75rem !important;
}

.simple-form.provider .services-card .services-details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
}

.simple-form.provider .services-card .services-details>div>span:first-of-type {
  padding-left: 13.25px;
  font-size: 0.895rem;
}

.simple-form.provider .services-card .services-details .MuiChip-root {
  display: block;
  height: 26px;
  margin-right: 16.25px;
  margin-top: 4.5px;
  vertical-align: baseline;
}

.simple-form.provider .services-card .services-details .MuiChip-root .MuiChip-deleteIcon {
  height: 20px;
  width: 40px;
  /* vertical-align: bottom;
  display: block; */
}

.simple-form.provider .provider-services .MuiToolbar-root {
  margin-top: -32px;
}

.simple-form.provider .provider-services .MuiTablePagination-root .MuiToolbar-root {
  margin-top: 0px;
}

.simple-form.provider .provider-services .MuiTablePagination-root .MuiToolbar-root .MuiButton-root {
  min-width: 48px;
}

.simple-form.provider .provider-services .MuiToolbar-root>.MuiButton-root {
  padding-left: 8px;
  padding-right: 8px;
}

.simple-form.provider .provider-services .MuiToolbar-root>.MuiButton-root:first-child {
  margin-right: 12px;
}

.simple-form.provider .MuiFormControl-root.multiple-select-large > div {
  width: 431.15px !important;
}

.simple-form.provider .MuiFormControl-root.multiple-select-large:not(.disabled) > .MuiFormLabel-root,
.simple-form.provider .MuiFormControl-root.multiple-select-small:not(.disabled) > .MuiFormLabel-root {
  color: #3f51b5;
}

.simple-form.provider .MuiFormControl-root.multiple-select-large .MuiFilledInput-underline:after,
.simple-form.provider .MuiFormControl-root.multiple-select-small .MuiFilledInput-underline:after {
  border-bottom-color: #3f51b5 !important;
}

.simple-form.provider .MuiFormControl-root.multiple-select-large .MuiChip-root,
.simple-form.provider .MuiFormControl-root.multiple-select-small .MuiChip-root {
  border: 2px solid #3f51b5;
}

.simple-form.provider .MuiFormControl-root.select-disabled .MuiInputBase-root {
  padding-top: 16px;
  padding-bottom: 1px;
  padding-left: 12px;
  position: relative;
  transition: background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  background-color: rgba(0, 0, 0, 0.09);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.simple-form.provider .MuiFormControl-root.select-disabled label+.MuiInput-formControl {
  margin-top: -1px !important;
}

.simple-form.provider .MuiFormControl-root.select-disabled label.MuiInputLabel-formControl.MuiInputLabel-shrink {
  margin-left: 12px !important;
  margin-top: -1px;
  transform: translate(0, 4.5px) scale(0.75) !important;
}

.simple-form.provider .title {
  margin: 0 auto 16.5px;
}

/* add service modal */


.simple-form.add-service .MuiFormControl-root {
  width: 256px;
}
.add-service--modal .MuiToolbar-root>.MuiButton-containedPrimary:not(:first-child) {
  background-color: #3f51b5 !important;
}
.service-template--modal .service-buttons {
  padding: 12.5px 10px;
}
.service-template--modal .selected-file {
  padding: 0 20px;
}
.service-template--modal .selected-file-title {
  display: block;
  margin-bottom: 6px;
}
.service-template--modal .MuiDialogActions-root {
  padding: 8px 32.5px;
}
.service-template--modal .MuiDialogActions-root .MuiButton-containedPrimary {
  background-color: #3f51b5 !important;
}
.service-template--modal .MuiDialogActions-root .MuiCircularProgress-colorPrimary {
  color: #fafafa !important;
}

@media (min-width: 960px) {
  .simple-form.add-service {
    min-height: 350px;
  }
  .service-template--modal .service-buttons > .MuiButton-root:nth-child(2) {
    margin-left: 85px;
  }
}

@media (max-width: 959.98px) {
  .service-template--modal .MuiDialogTitle-root > .MuiTypography-root {
    margin-right: 42px;
  }
  .service-template--modal .service-buttons {
    flex-direction: column;
  }
  .service-template--modal .service-buttons > .MuiButton-root:nth-child(2) {
    margin-top: 17.5px;
  }
}

@media (min-width: 600px) {
  /* Services stepper */
  .simple-form.provider .stepper-content {
    width: 100% !important;
  }
}

@media (max-width: 599.98px) {
  /* Services stepper */
  .simple-form.provider .stepper-content.services {
    width: 100% !important;
  }
  .simple-form.provider .address:first-of-type .MuiFormControl-root {
    margin-bottom: 32px !important;
  }
  .simple-form.provider .provider-services > div > .MuiToolbar-root {
    display: none !important;
  }
  .simple-form.provider .stepper-content>.MuiBox-root:nth-child(2)>.provider-services {
    text-align: center;
  }
  .simple-form .provider-services .MuiToolbar-root .MuiSvgIcon-root {
    display: block;
  }
  .simple-form.provider .services-title {
    min-width: 32%;
    padding-left: 8px;
    padding-right: 8px;
  }
  .simple-form.provider .services-card .services-details>div>span:first-of-type {
    padding-left: 0px;
  }
  .simple-form.provider .services-card .services-details .MuiChip-root {
    margin-bottom: 6px;
  }
  .simple-form.provider .services-card .services-details .MuiChip-root .MuiChip-deleteIcon {
    height: 20px;
    width: 18px;
    margin-bottom: -3.5px;
  }
  .simple-form.provider .AkRegisterForm-body .add-service,
  .simple-form.provider .AkRegisterForm-body .listed-services {
    width: 96.65%;
    padding: 0.5rem;
    margin-bottom: 10px;
    background-color: #f5f5f5;
    border-radius: 8px;
  }

  .simple-form.provider .form--subsection > div > .MuiToolbar-root {
    display: none !important;
  }

  .simple-form.provider .listed-services .MuiExpansionPanelSummary-content.Mui-expanded {
    margin: 0 0;
  }

  .simple-form.provider .services-list {
    padding-right: 0;
  }

  .simple-form.provider .services-title .MuiCardHeader-content > span:nth-child(2) {
    text-align: left;
  }

  .simple-form.provider .AkRegisterForm-body .add-service .MuiButton-root {
    display: block;
    /*margin-right: 0;*/
    margin: 0 auto !important;
  }
}

@media (max-width: 425.98px) {
  .MuiPaper-root .simple-form.only-services .MuiCardContent-root > .ra-input {
    width: 373px;
  }

  .simple-form.provider .AkRegisterForm-body .add-service,
  .simple-form.provider .AkRegisterForm-body .listed-services {
    width: 95%;
  }

  .simple-form.provider .form--subsection .MuiTablePagination-toolbar {
    float: left;
  }

  .simple-form.provider .form--subsection .MuiTablePagination-actions {
    margin-left: 120px;
  }
}

@media (max-width: 375.98px) {
  .MuiPaper-root .simple-form.only-services .MuiCardContent-root > .ra-input {
    width: 323px;
  }

  .simple-form.provider .AkRegisterForm-body .add-service,
  .simple-form.provider .AkRegisterForm-body .listed-services {
    width: 94%;
  }

  .simple-form.provider .form--subsection .MuiTablePagination-actions {
    margin-left: 86px;
  }

  .simple-form.provider .services-title {
    min-width: 25%;
    text-align: left;
  }

  .add-service--modal .MuiDialogContent-dividers {
    padding: 16px 10px;
  }
}

@media (max-width: 320.98px) {
  .MuiPaper-root .simple-form.only-services .MuiCardContent-root > .ra-input {
    width: 268px;
  }

  .simple-form.provider .AkRegisterForm-body .add-service,
  .simple-form.provider .AkRegisterForm-body .listed-services {
    width: 93.25%;
  }

  .simple-form.provider .form--subsection .MuiTablePagination-actions {
    margin-left: 35px;
  }

  .simple-form.only-services .MuiFormControl-root>div {
    width: 216px !important;
  }

  .add-service--modal .MuiDialogContent-dividers {
    padding: 16px 4px;
  }

  .simple-form.add-service .MuiFormControl-root {
    width: 216px;
  }
}


/* Requests
   ========================================================================== */


.provider-requests--modal .MuiDialog-paper, .provider-requests--modal .MuiDialog-paper .MuiCard-root {
  color: #0000009f;
}

.provider-requests--modal .MuiPaper-root .MuiTypography-h6, .requested-by-title .requester-names, .requested-by-title .requester-location {
  font-size: 1.2rem;
}

.provider-requests--modal .MuiPaper-root .details {
  margin: 0 auto;
}

.provider-requests--modal .MuiPaper-root .intro {
  font-weight: 600;
  font-size: 1.12rem;
}

.requested-by-card .requested-by-title .MuiCardHeader-content>.MuiCardHeader-title {
  margin-bottom: 8px;
}

.MuiCard-root.requested-by-card {
  margin-bottom: 0.35em;
}

.provider-requests--modal .MuiPaper-root .MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>th:first-child, .provider-requests--modal .MuiPaper-root .MuiTable-root>.MuiTableBody-root>.MuiTableRow-root>td:first-child {
  display: table-cell;
}

.provider-requests--modal .MuiPaper-root .MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>th:last-child {
  padding-right: 16px;
}

.provider-requests--modal .MuiPaper-root .MuiTable-root .MuiTableRow-root>.MuiTableCell-root {
  text-align: right;
  color: #0000009f;
}

.provider-requests--modal .MuiPaper-root .MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:first-child, .provider-requests--modal .MuiPaper-root .MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(2), .provider-requests--modal .MuiPaper-root .MuiTable-root>.MuiTableBody-root>.MuiTableRow-root:not(:last-child)>.MuiTableCell-root:first-child, .provider-requests--modal .MuiPaper-root .MuiTable-root>.MuiTableBody-root>.MuiTableRow-root:not(:last-child)>.MuiTableCell-root:nth-child(2) {
  text-align: left;
}

.MuiBox-root.two--btns {
  justify-content: space-between;
}

.MuiBox-root.one--btn {
  justify-content: flex-end;
}

.provider-requests--modal .MuiButton-root {
  font-weight: 600;
}

.request--modal .MuiToolbar-root>.MuiButton-containedPrimary:not(:first-child), .provider-requests--modal .confirm.MuiButton-containedPrimary {
  background-color: #3f51b5 !important;
}

.provider-requests--modal .accept.confirm--delivery {
  float: right;
}

.MuiFormLabel-root.Mui-focused {
  color: #3f51b5 !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #3f51b5 !important;
}

.request--modal .MuiDialog-paper {
  width: 320px;
}

.request--modal .MuiDialog-paper .MuiDialogTitle-root {
  padding: 16px 24px 0px;
}

.request--modal .MuiDialog-paper .MuiDialogContent-root {
  padding: 0px 24px 12px;
}

.request--modal .simple-form.request {
  min-height: 50px;
}

.request--modal .simple-form.request > .MuiCardContent-root:first-child {
  padding-left: 6px !important;
  padding-right: 6px !important;
  padding-bottom: 0px;
}

.request--modal .simple-form.request .MuiToolbar-root {
  margin-top: 38px;
}

.MuiPaper-root .simple-form.group.basic-form.second .MuiCardContent-root > .ra-input:nth-child(2) {
  background: #f5f5f5;
  border-radius: 6px;
}

@media (max-width: 599.98px) {

  /* farmer requests */
  main .list-page .MuiPaper-root.farmer-request--card:first-of-type {
    margin: 0 0 0.5rem 0;
  }
  .MuiPaper-root.farmer-request--card {
    color: #0000009f;
  }
  .farmer-request--card .MuiCardHeader-root {
    padding: 16px 16px 5px;
    border-bottom: 1px solid #0000001f;
  }
  .farmer-request--card .MuiTypography-h5, .farmer-request--card .MuiTypography-body2 {
    font-size: 1.09375rem;
  }
  .farmer-request--card .MuiCardContent-root .MuiBox-root>div:not(:first-child) {
    margin-top: 8px;
  }
  .farmer-request--card .MuiCardContent-root .MuiBox-root>div>span:first-child {
    font-weight: bold;
  }
  .action--menu .MuiMenuItem-root {
    font-weight: 600;
  }
  .MuiCard-root.requested-by-card {
    margin-bottom: 0.95em;
  }
  .provider-requests--modal .MuiPaper-root .MuiTypography-h6, .requested-by-title .requester-names, .requested-by-title .requester-location {
    font-size: 1.09375rem;
  }
  .provider-requests--modal .MuiDialogTitle-root {
    padding: 16px;
  }
  .provider-requests--modal .MuiDialogTitle-root .MuiIconButton-root {
    padding: 8px;
  }
  .provider-requests--modal .MuiTable-root .MuiTableRow-root .MuiTableCell-root:first-child {
    padding-left: 6.5px;
  }
  .provider-requests--modal .MuiPaper-root .MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>th:last-child, .provider-requests--modal .MuiTable-root .MuiTableRow-root .MuiTableCell-root:last-child {
    padding-right: 6.5px;
  }
  .provider-requests--modal .MuiPaper-root .MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:not(:last-child), .provider-requests--modal .MuiPaper-root .MuiTable-root>.MuiTableBody-root>.MuiTableRow-root:not(:last-child)>.MuiTableCell-root:not(:last-child) {
    text-align: left;
  }
  .provider-requests--modal .MuiPaper-root .MuiTable-root .MuiChip-label {
    padding-left: 2px;
    padding-right: 2px;
  }
  .small--btn.MuiIconButton-root {
    padding: 8px 10px 4px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
  /* affect modal "form" "no" button */
  .MuiToolbar-root>.small--btn.MuiIconButton-root {
    padding: 6px 16px;
    line-height: 1.75;
    box-shadow: none;
  }
  .MuiToolbar-root>.small--btn.MuiIconButton-colorPrimary:hover, .MuiToolbar-root>.small--btn.MuiIconButton-colorPrimary:focus, .MuiToolbar-root>.small--btn.MuiIconButton-colorPrimary:active {
    background-color: #df1f260a;
  }
  .confirm.MuiIconButton-colorSecondary {
    background: #3f51b5;
  }
  .reject.MuiIconButton-colorSecondary {
    background: #df1f26;
  }
  .small--btn.MuiIconButton-root .MuiSvgIcon-root {
    vertical-align: middle;
    margin-top: -5px;
  }
  .requested-by-title .MuiCardHeader-content {
    max-width: 250px;
  }
}


/* Groups
   ========================================================================== */


.simple-form.group.basic-form.second .details {
  margin: 8px 15px;
}

.simple-form.group .MuiExpansionPanel-root .user--link {
  color: #3f51b5;
}

.simple-form.group .MuiExpansionPanel-root .user--link:hover,
.simple-form.group .MuiExpansionPanel-root .user--link:focus,
.simple-form.group .MuiExpansionPanel-root .user--link:active {
  text-decoration: underline;
}

.simple-form.group .group--managers span.user-type {
  display: inline-block;
  text-transform: capitalize;
  margin-top: 0px;
}

@media (min-width: 600px) {
  /* Group/Cooperatives stepper*/
  .simple-form.provider.group .stepper-content {
    padding: 7px;
    width: 98.75% !important;
  }
}

@media (max-width: 599.98px) {

  /* groups stepper */

  .simple-form.group .stepper-content.groups-form {
    width: 96% !important;
    margin: 0 auto;
    padding-bottom: 6.95px;
  }

  .simple-form.group.register-form .stepper-content.groups-form {
    width: 100% !important;
    margin: 0 auto;
    padding-bottom: 6.95px;
  }

  .simple-form.group > div:last-child {
    height: 8em; /* spacer */
  }

  .simple-form.group.register-form .add-role .MuiFormControl-root {
    margin: 0 auto !important;
  }

  .simple-form.group .AkRegisterForm-body .add-managers,
  .simple-form.group .AkRegisterForm-body .add-role {
    width: 96%;
    padding: 0.5rem;
    margin-bottom: 10px;
    background-color: #f5f5f5;
    border-radius: 8px;
  }

  .simple-form.group .AkRegisterForm-body .MuiExpansionPanelSummary-content {
    margin-left: 5.65%;
  }

  .simple-form .AkRegisterForm-role {
    display: block;
    margin-bottom: 15px;
  }

  .simple-form.group .check {
    display: block !important;
    width: 100% !important;
  }

  .simple-form fieldset .MuiFormGroup-root {
    width: 100% !important;
  }

  .simple-form fieldset legend {
    text-align: center;
    margin-bottom: 15px;
    margin-left: 5.65%;
  }

  .simple-form .address.details .MuiFormControl-root {
    margin-bottom: 32px !important;
  }

  .simple-form.group .group--managers .AkRegisterForm-body {
    text-align: left;
  }

  .simple-form.group .group--managers .AkRegisterForm-body .MuiExpansionPanelSummary-content {
    margin-left: 0px;
    align-items: center;
  }

  .simple-form.group .group--managers .AkRegisterForm-body .MuiExpansionPanelSummary-content > p {
    flex-basis: 62.5%;
  }

  .simple-form.group .group--managers .AkRegisterForm-body .MuiExpansionPanelSummary-content > p:last-child {
    font-size: 1rem;
  }

  .simple-form .multiple-select-small .MuiFormLabel-root {
    font-size: 1.05rem;
  }

  .user-registration .add-to-group .select-group {
    max-width: 250px;
    margin-right: auto;
    margin-left: auto;
  }
  .user-registration .add-to-group .select-group .MuiListItem-root {
    padding-left: 6px;
    padding-right: 6px;
  }
}

@media (max-width: 425.98px) {
  .basic-form .MuiFormControl-root[class*='input']>div, .basic-form .MuiFormControl-root>div, .register-form .MuiFormControl-root[class*='input']>div, .register-form .MuiFormControl-root>div, .simple-form.provider .address.details.edit ul section>div:not(:nth-child(4)) {
    width: 250px !important;
  }
  .simple-form fieldset .MuiFormGroup-root {
    width: 100% !important;
  }
  .simple-form fieldset .MuiFormHelperText-root {
    text-align: center;
  }
}

@media (max-width: 320.98px) {
  .simple-form.group .AkRegisterForm-body .add-managers,
  .simple-form.group .AkRegisterForm-body .add-role {
    width: 87%;
  }
}


/* MAAIF integration
   ========================================================================== */


.akb .MuiChip-root,
.akb .MuiButton-root,
.akb .MuiMenuItem-root,
.AkUserMenu-root .MuiAvatar-root,
.AkUserMenu-root .MuiMenuItem-root,
.akb .MuiFormLabel-root,
.akb .MuiTab-root,
.akb .MuiTableCell-root,
.akb .MuiTypography-root {
  font-family: "Poppins", sans-serif;
}

.akb .login-section {
  display: flex;
  flex-direction: column;
}

.akb .login-guide-text {
  margin-bottom: 12px;
  text-align: center;
  color: #0000008a;
}
.akb .login-form .MuiFormLabel-root {
  font-size: 0.95rem;
}

.akb .MuiButton-textSizeSmall {
  font-size: 12.5px;
}

.MuiPaper-root .login-form > .MuiCardActions-root .MuiButton-containedPrimary {
  color: #FFFFFF;
  padding: 0.7rem 6rem;
  font-size: 14px;
  background: linear-gradient(#069751, #066a3a);
  margin-top: 0.5rem;
  text-align: center;
  border-radius: 4px;  
}

.MuiPaper-root .login-form > .MuiCardActions-root .MuiButton-containedPrimary:hover, 
.MuiPaper-root .login-form > .MuiCardActions-root .MuiButton-containedPrimary:focus,
.MuiPaper-root .login-form > .MuiCardActions-root .MuiButton-containedPrimary:active { 
  color: #DDDDDD;  
}

.MuiPaper-root .login-form > div:first-child .MuiIconButton-root {
  padding: 0px;
}

.MuiPaper-root .login-form .MuiInputBase-root:hover,
.MuiPaper-root .login-form .MuiInputBase-root:focus,
.MuiPaper-root .login-form .MuiInputBase-root:active {
  color: #0000008a;
}

.MuiPaper-root .login-form .MuiInputBase-root,
.MuiPaper-root .login-form > div:first-child .MuiSvgIcon-root {
  color: #0000008a;
}

.akb .MuiPaper-root .MuiTable-root .MuiTableCell-root {
  padding: 0.75rem;
}

.login .MuiSvgIcon-root .MuiButtonBase-root {
  background: linear-gradient(#069751, #066a3a);
  font-size: 12px;
  font-family: inherit;
}

.layout > div {
  margin-top: 0px;
}

.layout main { 
  min-height: 800px;
  background: linear-gradient(rgb(248, 250, 252), rgb(208, 222, 217));
}

header.MuiPaper-root {
  padding: 0.25rem 1rem;
  color: rgba(0, 0, 0, 0.5);
  box-shadow:  0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

header > .MuiToolbar-root {
  min-height: 51.5px;
}

header.entry-login > .MuiToolbar-root {
  padding-left: 15px;
}

header.entry-login > .MuiToolbar-root .MuiIconButton-root {
  display: none;
}

header > .MuiToolbar-root > .MuiButtonBase-root {
  margin-right: 22.5px;
}

header > .MuiToolbar-root .MuiSvgIcon-root {
  font-size: large;
  width: 0.85em;
  height: 0.85em;
}

header > .MuiToolbar-root .MuiTypography-root {
  visibility: hidden;
}

/* main body */

main [class*='page'] .MuiPaper-root:first-of-type {
  padding: 0 2.125rem 2.125rem 2.125rem;
}

main .MuiPaper-rounded {
  border-radius: 0px;
}

main > div:nth-child(2) {
  width: 100%;
  padding-top: 75px;
  padding-bottom: 35px;
  padding-right: 42px;
  padding-left: 42px;
  margin-right: auto;
  margin-left: auto;
}

main > div:nth-child(2) > div > div > .MuiPaper-root:first-of-type {
  box-shadow: 0 0 3px 1px #dddddd;
}

@media (min-width: 1200px) {
  main > div:nth-child(2) {
    max-width: 1114px;
  }
}

main > .MuiDrawer-root {
  background: #43425d;
  z-index: 1115;
}

main > .MuiDrawer-root > .MuiPaper-root {
  margin-top: 0px !important;
}

main > .MuiDrawer-root > .MuiPaper-root .MuiButtonBase-root {
  margin-bottom: 0 !important;
  font-size: 12.85px;
  font-weight: 500;
  height: 54px;
}

main > .MuiDrawer-root > .MuiPaper-root .MuiButtonBase-root,
main > .MuiDrawer-root > .MuiPaper-root .MuiButtonBase-root .MuiTypography-root {
  color: #DDDDDD;
}

main > .MuiDrawer-root > .MuiPaper-root .MuiButtonBase-root .MuiListItemIcon-root {
  color: #a4bfa7;
  min-width: 40px;
}

main > .MuiDrawer-root > .MuiPaper-root .MuiButtonBase-root .MuiSvgIcon-root {
  height: 0.85em;
}

main > .MuiDrawer-root > .MuiPaper-root a.MuiButtonBase-root:first-of-type {
  margin-top: 16.5px;
}

main > .MuiDrawer-root > .MuiPaper-root a.MuiListItem-root {
  padding-left: 14px;
}

main > .MuiDrawer-root > .MuiPaper-root a.AkListItem-image.MuiListItem-root {
  padding-left: 16px;
}

main > .MuiDrawer-root > .MuiPaper-root .AkListItem-line {
  border: 0;
  height: 1px;
  background: #3b3b54;
}

/* user menu styling */

.AkUserMenu-root > .MuiPaper-root {
  top: 55.5px !important;
  left: 1058px !important;
  width: 300px;
  border-radius: 8px;
  border: 1px solid transparent;
  box-shadow: 0 0 3px 1px #dddddd !important;  
}

.AkUserMenu-root .MuiList-padding {
  padding-top: 0px;
  padding-bottom: 0px;
}

.AkUserMenu-root .MuiMenuItem-root {
  font-size: 1.02rem;
  font-weight: 500;
  flex-wrap: wrap;
}

.AkUserMenu-root .MuiMenuItem-root:first-of-type {
  padding: 12px 2.25rem;
  background: #dfeffc;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.AkUserMenu-root .MuiMenuItem-root:first-of-type > .MuiSvgIcon-root {
  display: none;
}

.AkUserMenu-root .MuiMenuItem-root:nth-of-type(2) {
  padding: 1.2rem 2.85rem 2rem;
  color: #2b4fa7;
  font-weight: 600;
}

.AkUserMenu-root .MuiListItemIcon-root {
  vertical-align: middle;
}

.AkUserMenu-root .MuiAvatar-root {
  display: inline-flex;
  vertical-align: middle;
  width: 45px;
  height: 45px;
  margin-right: 24px;
  margin-top: 13px;
  background: #e2e8f0;
}

.AkUserMenu-root .MuiAvatar-root .MuiSvgIcon-root {
  font-size: 1.15rem;
  color: #648a8e;
}

.AkUserMenu-root .MuiMenuItem-root:nth-of-type(2) .txt:nth-child(2) {
  margin-top: -17px;
}

.AkUserMenu-root .MuiMenuItem-root:nth-of-type(2) .txt {
  color: #51575d;
  margin-left: 70px;
  font-size: 0.675rem;
  font-weight: 500;
}

.AkUserMenu-root .MuiListItem-button:first-of-type:hover,
.AkUserMenu-root .MuiListItem-button:first-of-type:focus,
.AkUserMenu-root .MuiListItem-button:first-of-type:active {
  cursor: text;
}

.AkUserMenu-root .MuiListItem-button:nth-of-type(2):hover,
.AkUserMenu-root .MuiListItem-button:nth-of-type(2):focus,
.AkUserMenu-root .MuiListItem-button:nth-of-type(2):active {
  background-color: transparent !important;
}

.MuiDrawer-root .sidebar-head-title {
  min-height: 60px;
  background: #3b3b53;
  justify-content: center;
  text-align: center;
}

.MuiDrawer-root .sidebar-head-title img {
  height: 30px;
  margin-top: 15px;
  vertical-align: bottom;
  display: inline-flex;
}

.MuiDrawer-root .sidebar-head-title .MuiTypography-root {
  display: inline-flex;
  color: #99a69a;
  margin-left: 12px;
  font-size: 15.5px;
  font-weight: 600;
  letter-spacing: -0.25px;
}

.title {
  color: #497c55;
  padding: 10px;
  margin: 32px auto;
  font-size: 44.5px;
  font-weight: 500;
}

.sub-title {
  color: #497c55;
  padding: 10px;
  margin: 0px auto 5px;
  font-size: 23.5px;
  font-weight: 500;
}

.sub-title .highlight {
  text-decoration: underline;
}

.placement {
  display: inline-flex;
}

.placement.MuiSvgIcon-root {
  color: #497c55;
  vertical-align: sub;
}

.list-page > .MuiToolbar-root button.MuiButtonBase-root,
.MuiPaper-root .MuiTable-root > .MuiTableHead-root > .MuiTableRow-root > th:first-child, 
.MuiPaper-root .MuiTable-root > .MuiTableBody-root > .MuiTableRow-root > td:first-child {
  display: none;
}

.MuiPaper-root .MuiTable-root > .MuiTableHead-root > .MuiTableRow-root > .MuiTableCell-root {
  color: #999999;
  padding: 1.05rem;
  background: #e9ecef;
  text-transform: uppercase;
  font-size: 11.75px;  
} 

.MuiPaper-root .MuiTable-root > .MuiTableHead-root > .MuiTableRow-root > .MuiTableCell-root:last-child {
  text-align: center;
}

.list-page .MuiTableCell-root .MuiChip-root {
  background: transparent;
}

.list-page .MuiPaper-root .MuiTableCell-root, 
.list-page .MuiPaper-root .MuiTypography-root {
  font-size: 13.5px;
}

.list-page .MuiPaper-root > .MuiTable-root {
  /* equivalent to button height on top of farmer/provider tables */
  margin-top: 48px;
}

@media (max-width: 599.98px) {
  .simple-form.provider .AkRegisterForm-body .add-service,
  .simple-form.provider .AkRegisterForm-body .listed-services {
    width: 96.65%;
    padding: 0.5rem;
    margin-bottom: 10px;
    background-color: #f5f5f5;
    border-radius: 8px;
  }

  .simple-form.provider .form--subsection > div > .MuiToolbar-root {
    display: none !important;
  }

  .simple-form.provider .listed-services .MuiExpansionPanelSummary-content.Mui-expanded {
    margin: 0 0;
  }

  .simple-form.provider .services-list {
    padding-right: 0;
  }

  .simple-form.provider .services-title .MuiCardHeader-content > span:nth-child(2) {
    text-align: left;
  }

  .simple-form.provider .AkRegisterForm-body .add-service .MuiButton-root {
    display: block;
    /*margin-right: 0;*/
    margin: 0 auto !important;
  }
}

@media (max-width: 425.98px) {
  .MuiPaper-root .simple-form.only-services .MuiCardContent-root > .ra-input {
    width: 373px;
  }

  .simple-form.provider .AkRegisterForm-body .add-service,
  .simple-form.provider .AkRegisterForm-body .listed-services {
    width: 95%;
  }

  .simple-form.provider .form--subsection .MuiTablePagination-toolbar {
    float: left;
  }

  .simple-form.provider .form--subsection .MuiTablePagination-actions {
    margin-left: 120px;
  }
}

@media (max-width: 375.98px) {
  .MuiPaper-root .simple-form.only-services .MuiCardContent-root > .ra-input {
    width: 323px;
  }

  .simple-form.provider .AkRegisterForm-body .add-service,
  .simple-form.provider .AkRegisterForm-body .listed-services {
    width: 94%;
  }

  .simple-form.provider .form--subsection .MuiTablePagination-actions {
    margin-left: 86px;
  }

  .simple-form.provider .services-title {
    min-width: 25%;
    text-align: left;
  }

  .add-service--modal .MuiDialogContent-dividers {
    padding: 16px 10px;
  }
}

@media (max-width: 320.98px) {
  .MuiPaper-root .simple-form.only-services .MuiCardContent-root > .ra-input {
    width: 268px;
  }

  .simple-form.provider .AkRegisterForm-body .add-service,
  .simple-form.provider .AkRegisterForm-body .listed-services {
    width: 93.25%;
  }

  .simple-form.provider .form--subsection .MuiTablePagination-actions {
    margin-left: 35px;
  }

  .simple-form.only-services .MuiFormControl-root>div {
    width: 216px !important;
  }

  .add-service--modal .MuiDialogContent-dividers {
    padding: 16px 4px;
  }

  .simple-form.add-service .MuiFormControl-root {
    width: 216px;
  }
}

/* Other
   ========================================================================== */

